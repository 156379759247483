import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import nmbrsLogo from "../../assets/nmbrs_logo.svg";
import nmbrsLogoWhite from "../../assets/nmbrs_logo_white.svg";
import styles from "./Layout.module.css";
import { FreeTrialRequest, getIsFreeTrial } from "../../api";


const Layout = (): JSX.Element => {
    const [isFreeTrial, setIsFreeTrial] = useState<boolean | null>(null);

    const getLogoSrc = () => {
        const env_logo = String(sessionStorage.getItem("env_logo"));
        const headerIsNmbrsBlue = (getHeaderColor() == "#1c98eb" || getHeaderColor() == "#007ACA") || isFreeTrial;
        if (env_logo == "" && headerIsNmbrsBlue){
            return nmbrsLogoWhite;
        }
        else if (env_logo == "") {
            return nmbrsLogo;
        }
        else {
            return `data:image/png;base64,` + `${env_logo}`
        }
    };

    const getAccessToken = () => {
        return String(sessionStorage.getItem("access_token"));
    };

    const getTenantId = () => {
        return String(sessionStorage.getItem("tenant_id"));
    };

    const getHeaderColor = () => {
        return String(sessionStorage.getItem("header_color"));
    };

    const fetchFreeTrial = async () => {
        try {
            const request: FreeTrialRequest = {                
                accessToken: getAccessToken(),
                tenantId: getTenantId()
            };
            const result = await getIsFreeTrial(request);
            setIsFreeTrial(result.freeTrial);
        } catch (e) {
            setIsFreeTrial(false);
        }
    };

    useEffect(() => {
        fetchFreeTrial().catch(() => {});
    }, []);

    return (
        <div className={styles.layout}>
                <header
                    className={isFreeTrial ? styles.header : styles.tenant_header}
                    style={isFreeTrial ? {} : { backgroundColor: getHeaderColor() }}
                >
                    <button onClick={() => window.location.reload()} className={styles.homeButton}>
                        <img src={isFreeTrial ? nmbrsLogoWhite : getLogoSrc()} alt={isFreeTrial ? "Nmbrs logo" : "company logo"} />
                    </button>

                </header>
            <Outlet />
        </div>
    );
};

export default Layout;
