import { Stack } from "@fluentui/react";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";

export const AnswerLoading = () => {

    return (
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <AnswerIcon />
                <Stack.Item grow>
                    <p className={styles.answerText}>
                        Aan het zoeken<span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
    );
};
