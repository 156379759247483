import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";

import styles from "./QuestionInput.module.css";
import { SendChatButton } from "../SendChatButton";
import { ClearChatButton } from "../ClearChatButton";
import { Constants } from "../../utils/Constants";

interface Props {
    onSend: (question: string) => void;
    sendDisabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    clearChatDisabled: boolean;
    clearChat: () => void;
    setFooterHeight: (height: number) => void;
}

export const QuestionInput = ({ onSend, sendDisabled, placeholder, clearOnSend, clearChatDisabled, clearChat, setFooterHeight }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (sendDisabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = ev.target.value;
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 3000) {
            setQuestion(newValue);
        }
    };

    useEffect(() => {
        const textarea = document.querySelector(`.${styles.questionTextField}`) as HTMLTextAreaElement;
        if (textarea) {
            const LINE_HEIGHT = 1.5;
            textarea.style.height = `${LINE_HEIGHT}em`; // Reset height to single line
            const scrollHeight = Math.min(textarea.scrollHeight, 4 * 1.5 * 16 * 0.9); // Max 4 lines (6em)
            textarea.style.height = `${scrollHeight}px`;
            setFooterHeight(Constants.STANDARD_FOOTER_HEIGHT - (16 * LINE_HEIGHT) + scrollHeight);            
        }
    }, [question]);

    const sendQuestionDisabled = sendDisabled || !question.trim();

    return (
        <Stack horizontal className={styles.questionInputContainer}>            
            <ClearChatButton className={styles.clearChat} onClick={clearChat} disabled={clearChatDisabled} />
            <textarea
                id="chat-input"
                className={styles.questionTextField}
                placeholder={placeholder}
                autoFocus
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <SendChatButton className={styles.clearChat} onClick={sendQuestion} disabled={sendQuestionDisabled}/>
        </Stack>    
    );
};
